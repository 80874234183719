import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/ThreeColWithSideImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import TwoColContactUsFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Footer from 'components/footers/MiniCenteredFooter.js';

const HighlightedText = tw.span`text-primary-500`

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features id="servicesSection"
        heading={<>We have Amazing <HighlightedText>Services</HighlightedText></>}
      />      
      <Testimonial
        heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
      />
      <TwoColContactUsFullForm />
      <Footer />
   </AnimationRevealPage>
  );
}
