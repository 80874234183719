import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
import Footer from 'components/footers/MiniCenteredFooter.js';
// import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
// import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"
import sliderImage from "images/sliderImage3.svg";
import TwoColContactUsFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
// import Signup from "pages/Signup";
import KeyServicesPage from "pages/keyServices";
import OurStatsPage from "pages/Testimonials/index";


export default () => {
  return (
  <AnimationRevealPage>
      <Hero />
      <KeyServicesPage/>
      {/* <Signup /> */}
      {/* <Features /> */}
      {/* <Pricing /> */}
      {/* <MainFeature 
        subheading="Reliable"
        heading="Highly Redundant Servers With Backup"
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
      /> */}
      <MainFeature 
        subheading="Reliable"
        heading="Highly Redundant Services "
        imageSrc={sliderImage}
        buttonRounded={false}
        textOnLeft={false}
      />
      <OurStatsPage />
        <TwoColContactUsFullForm />
      {/* <Testimonial /> */}
      {/* <FAQ /> */}
      <Footer />
    </AnimationRevealPage>
  );
}
