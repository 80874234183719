import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

// import sliderImage from "images/sliderImage3.svg";

import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";




const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;const Paragraph = tw.p`my-5 lg:my-4 text-sm lg:text-base font-medium text-white max-w-lg mx-auto lg:mx-0 text-center`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("banner.jpg");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-3xl lg:text-4xl xl:text-5xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

// const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/index.html">
      
      </NavLink>
      <NavLink href="/index.html">
      
      </NavLink><NavLink href="/index.html">
      
      </NavLink>    <NavLink href="/index.html">
      
      </NavLink>
      <NavLink href="/index.html">
      
      </NavLink><NavLink href="/index.html">
      
      </NavLink>
      <NavLink href="/index.html">
      Home
      </NavLink>
      <NavLink href="#servicesSection">
      What We Serve
      </NavLink>
      <NavLink href="#clientLove">
      Clients Love
      </NavLink>
      <NavLink href="#contactUs">
      Contact Us
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      {/* <PrimaryLink href="/#">
       
      </PrimaryLink> */}
    </NavLinks>
  ];


  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
          Free GPS App & traffic app <br></br> offer GPS Maps with directions.
             
          </Heading>
          <Paragraph>Easy, Fast ,and accurate voice navigation and driving directions for you, 100% free</Paragraph>
          <PrimaryLink href="https://play.google.com/store/apps/developer?id=Delta+Raza+Studio">Download Apps</PrimaryLink>
        </Content>
      </HeroContainer>
    </Container>
  );
};
